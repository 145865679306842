import { useState, useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

import './App.css';

function App() {
  const [data, setData] = useState([]);
  const [, setErro] = useState(null);


  const getRun = () => {
    fetch('https://ubergoianinha.com.br:8080/address/all')
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro na requisição');
        }
        return response.json();
      })
      .then(data => {
        setData(data);
      })
      .catch(error => {
        setErro(error.message);
    });
  };

  useEffect(() => {
     getRun();

     const intervalId = setInterval(getRun, 5000);
 
     return () => clearInterval(intervalId);
  }, []);

  const handleFinishRun = (id, business_phone_number_id, number_phone) => {
    fetch('https://ubergoianinha.com.br:8080/whatsapp/driver/finish', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          id: `${id}`,
          accept: true,
          business_phone_number_id: `${business_phone_number_id}`,
          number_phone: `${number_phone}`
        }
      )
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro na requisição');
      }
      return response.json();
    })
    .then(data => {
      getRun();
    })
    .catch(error => {
      setErro(error.message);
    });
  }

  const handleCancelAfterClient = (id, business_phone_number_id, number_phone) => {
    fetch('https://ubergoianinha.com.br:8080/whatsapp/driver/run/deny/after/client', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          id: `${id}`,
          accept: true,
          business_phone_number_id: `${business_phone_number_id}`,
          number_phone: `${number_phone}`
        }
      )
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro na requisição');
      }
      return response.json();
    })
    .then(data => {
      getRun();
    })
    .catch(error => {
      setErro(error.message);
    });
  }

  const handleCancelRun = (id, business_phone_number_id, number_phone) => {
    fetch('https://ubergoianinha.com.br:8080/whatsapp/driver/deny', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          id: `${id}`,
          accept: true,
          business_phone_number_id: `${business_phone_number_id}`,
          number_phone: `${number_phone}`
        }
      )
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro na requisição');
      }
      return response.json();
    })
    .then(data => {
      getRun();
    })
    .catch(error => {
      setErro(error.message);
    });
  }

  const handleConfirmRun = (id, business_phone_number_id, number_phone) => {
    fetch('https://ubergoianinha.com.br:8080/whatsapp/driver/accept', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        { 
          id: `${id}`,
          accept: true,
          business_phone_number_id: `${business_phone_number_id}`,
          number_phone: `${number_phone}`
        }
      )
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro na requisição');
      }
      return response.json();
    })
    .then(data => {
      getRun();
    })
    .catch(error => {
      setErro(error.message);
    });
  }
  return (
    <div className="container">
      <header className="container__header">
        <h2 className="container__header--logo">Uber Goianinha</h2>
      </header>
      <div className="container--list">
        {data?.map((address) => {
          if(!address.isClient || address.isCancel || address.isCanceledByDriver || address.isFinish){
            if(!address.isDriver || address.isCanceledByDriver || address.isFinish)
              return <></>
          }
          
          if(address.isClient && address.isDriver && address.isCancel){
            return (
              <div key={address?.id} className="container--list--card">
                <div>
                  <strong>{address?.number_phone_client}</strong>
                  <p>Local: {address?.street}, {address?.number} - {address?.neighborhood} - {address?.city} / RN</p>
                  <p><strong>Destino:</strong> {address?.neighborhood_destination}</p>
                  <div className="container--list__button">
                    <button 
                      onClick={() => handleCancelAfterClient(address?.id, address?.business_phone_number_id, address?.number_phone_client)} 
                      className="container--list__buttondeny">
                      CORRIDA CANCELADA PELO CLIENTE!
                    </button>
                  </div>
                </div>
                <a target="__blank" href={`https://api.whatsapp.com/send/?phone=${address?.number_phone_client}&text&type=phone_number&app_absent=0`} className="container--button__whatsapp">
                  <FaWhatsapp color="#FFFFFF" size={32} />
                </a>
              </div>
            )
          }

          return(
            <div key={address?.id} className="container--list--card">
              { address?.isLocationOfClient &&
                <a target="__blank" href={`https://maps.google.com/?q=${address?.latitude},${address?.longitude}`} className="container--button__location">
                  <FaLocationDot color="#FFFFFF" size={32} />
                </a>
              }
              <div>
                {address?.isDriver &&
                  <div className="container--list__button">
                    <button onClick={() => handleFinishRun(address?.id, address?.business_phone_number_id, address?.number_phone_client)} className="container--list__buttonconfirm">
                      CONCLUIR CORRIDA 
                    </button>
                  </div>
                }
                <strong>{address?.number_phone_client}</strong>
                <p>Local: {address?.street}, {address?.number} - {address?.neighborhood} - {address?.city} / RN</p>
                <p><strong>Destino:</strong> {address?.neighborhood_destination}</p>
                {address?.isDriver ?
                  <div className="container--list__button">
                    <button 
                      onClick={() => handleCancelRun(address?.id, address?.business_phone_number_id, address?.number_phone_client)}  
                      className="container--list__buttondeny"
                    >
                      CANCELAR CORRIDA
                    </button>
                  </div>
                :
                  <button className="container--list__buttonconfirm" onClick={() => handleConfirmRun(address?.id)}>
                    ACEITAR CORRIDA
                  </button>
                }
              </div>
              <a target="__blank" href={`https://api.whatsapp.com/send/?phone=${address?.number_phone_client}&text&type=phone_number&app_absent=0`} className="container--button__whatsapp">
                <FaWhatsapp color="#FFFFFF" size={32} />
              </a>
            </div>
        )})}
      </div>
    </div>
  );
}

export default App;
